import React, { useState } from "react";
import Typewriter from "typewriter-effect";

import { Container } from "react-bootstrap";

const CommandList = ({ toggleMain, counterVal = 0 }) => {
  const [shouldPrintResult, setShouldPrintResult] = useState(false);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const nextLine = async () => {
    setShouldPrintResult(!shouldPrintResult);
    sleep(3500);
    document.getElementById("hiddenDiv").style.display = "block";
    document.getElementById("whoamidiv").style.display = "none";
    toggleMain();
  };

  return (
    <Container
      fluid={true}
      className={`p-2 ps-3 text-light text-start ${
        counterVal < 11 ? "d-block" : "d-none"
      }`}
    >
      <div id="whoamidiv" className="text-secondary">
        <Typewriter
          options={{ cursor: "_" }}
          onInit={(typewriter) => {
            typewriter
              .pauseFor(3800)
              .pasteString("$ ")
              .pauseFor(500)
              .typeString("whoami")
              .pauseFor(1000)
              .callFunction(() => {
                nextLine();
              })
              .start();
          }}
        />
      </div>
      <div id="hiddenDiv" style={{ display: "none" }}>
        <p className="mb-1 text-secondary">$ whoami</p>
        <p className="mb-1">Hey, I'm</p>
        <p className="mb-1">Shinjith P R,</p>
        <p className="mb-1">an engineering student,</p>
        <p className="mb-1">software developer in training,</p>
        <p className="mb-1">... and just a wandering soul.</p>
        <div className="text-secondary">
          <Typewriter
            options={{ cursor: "_" }}
            onInit={(typewriter) => {
              typewriter
                .pasteString("$ ")
                .pauseFor(7000)
                .typeString("exit")
                .start();
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default CommandList;
