import React from "react";
import About from "./About";
import Contact from "./Contact";
import FacePage from "./FacePage";
import Footer2 from "./Footer2";
import Projects from "./Projects";

const ReadyPage = ({ chosenPage = 0, clickLink }) => {
  switch (chosenPage) {
    case 0:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <FacePage />
        </section>
      );
    case 1:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <About />
        </section>
      );
    case 2:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <Projects />
        </section>
      );
    case 3:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <Contact />
        </section>
      );
    case 4:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <Footer2 clickLink={clickLink} />
        </section>
      );
    default:
      return (
        <section className="p-0" style={{ height: "100vh", width: "100vw" }}>
          <FacePage />
        </section>
      );
  }
};

export default ReadyPage;
