import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";

const Contact = () => {
  return (
    <div id="contact" className="container-fluid w-100 h-100 bg-white">
      <div className="container-md d-grid align-items-center h-100 pt-5 ">
        <Row className="mx-auto">
          <Card
            id="contact-card"
            className="col-12 mx-auto border-primary border border-2 rounded text-light p-1 shadow-lg p-sm-3"
          >
            <Card.Body className="d-grid">
              <Card.Title as="h3" className="display-5 text-light mb-3">
                Contact
              </Card.Title>
              <Row>
                <Col md={6} className="p-0 p-sm-1">
                  <h5 className="ps-1 mb-3 opacity-75">
                    Have something to say?
                  </h5>
                  <Form className="d-grid text-primary">
                    <Row>
                      <Col lg={6} className="mb-2">
                        <InputGroup>
                          <InputGroup.Text className="fspan">
                            <span className="bi bi-person-fill"></span>
                          </InputGroup.Text>
                          <FormControl
                            type="text"
                            id="inputName"
                            className="ffield"
                            placeholder="My Name Is"
                            required={true}
                          ></FormControl>
                        </InputGroup>
                      </Col>
                      <Col lg={6} className="mb-2">
                        <InputGroup>
                          <InputGroup.Text className="fspan">
                            <span className="bi bi-at"></span>
                          </InputGroup.Text>
                          <FormControl
                            type="email"
                            id="inputEmail"
                            className="ffield"
                            placeholder="Email Is"
                            required={true}
                          ></FormControl>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-2">
                        <InputGroup>
                          <InputGroup.Text className="fspan">
                            <span className="bi bi-chat-fill"></span>
                          </InputGroup.Text>
                          <FormControl
                            type="text"
                            as="textarea"
                            id="inputName"
                            className="ffield"
                            placeholder="And I'd Like To Chat About"
                            required={true}
                            rows={4}
                          ></FormControl>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pe-2 opacity-75">
                        <Button variant="primary" className="float-end">
                          {"SEND "}
                          <i className="bi bi-send-fill ms-1"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col
                  md={6}
                  className="p-0 ps-sm-2 ps-lg-3 pt-5 mt-md-0 opacity-50"
                >
                  <h5 className="mb-2">Contact Info</h5>
                  <div>
                    <p className="fw-bold mb-2">
                      <i className="bi bi-telephone-fill mx-2"></i>+918921345282
                    </p>
                  </div>
                  <div>
                    <p className="fw-bold mb-2">
                      <i className="bi bi-pin-angle-fill mx-2"></i>Kasaragod,
                      Kerala, India
                    </p>
                  </div>
                  <div>
                    <p className="fw-bold mb-2">
                      <i className="bi bi-envelope-fill mx-2"></i>
                      shinjithkanhangad@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
