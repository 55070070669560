import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Navigator from "./Navigator";
import PageStack from "./PageStack";
import ReadyPage from "./ReadyPage";

const MainPage = ({ showIntro }) => {
  const [showStack, setShowStack] = useState(false);
  const [activeDot, setActiveDot] = useState(0);
  const [linkClicked, setLinkClicked] = useState([0, 0, 0, 0, 0]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showAnimation = async (value) => {
    if (value !== activeDot) {
      setActiveDot(value);
      setShowStack(true);
      await sleep(1000);
      setShowStack(false);
    }
  };

  return (
    <>
      <Container
        fluid={true}
        className="p-0 m-0"
        style={{
          width: "100vw",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Header showIntro={showIntro} color={activeDot === 4 ? 1 : 0} />
        <Navigator
          dotPosition={(value) => {
            showAnimation(value);
          }}
          linkClicked={linkClicked}
        />
        {showStack ? (
          <PageStack chosenPage={activeDot} />
        ) : (
          <ReadyPage
            chosenPage={activeDot}
            clickLink={(value) => {
              let array = [0, 0, 0, 0, 0];
              array[value] = 1;
              setLinkClicked(array);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default MainPage;
