import React from "react";
import { Container } from "react-bootstrap";
import IntroBar from "./IntroBar";
import CommandList from "./CommandList";

const Intro = ({ toggleMain, counterVal = 0 }) => {
  return (
    <Container
      id="window-cont"
      className="bg-primary d-flex align-items-center"
      fluid={true}
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Container id="terminal-window" className="p-0">
        <div
          id="terminal-title"
          className="d-flex text-center justify-content-center p-0 w-100"
        >
          <IntroBar counterVal={counterVal} />
        </div>

        <CommandList toggleMain={toggleMain} counterVal={counterVal} />
      </Container>
    </Container>
  );
};

export default Intro;
