import React from "react";
import { Container } from "react-bootstrap";

const IntroBar = ({ counterVal = 0 }) => {
  return (
    <Container fluid={true} className="p-0 d-flex justify-content-center w-100">
      <div
        id="wind-title"
        className={`px-2 pt-1 ${counterVal < 10 ? "visible" : "invisible"}`}
      >
        <div className="d-flex align-items-center">
          <span id="pop-up-dot" className="p-0"></span>
          <span
            className="mx-1"
            style={{
              height: "0.8rem",
              width: "0.8rem",
              borderRadius: "50px",
              backgroundColor: "#ffc100",
              boxShadow: "inset 0.5px 0.5px 1px var(--primary-10)",
            }}
          ></span>
          <span id="pop-down-dot"></span>
        </div>
        <div id="wind-title-text" className="flex-fill">
          <code className="text-dark"> shinjith-dev</code>
        </div>
      </div>
    </Container>
  );
};

export default IntroBar;
