import React from "react";
import Page from "./Page";

const StackItem = ({ cid, position, zIndex, chosenPage, animated }) => {
  return (
    <div
      className={`p-0 bg-white w-100 h-100 stack-item stack-item-${position} ${
        chosenPage === cid && !animated ? "animate" : ""
      }`}
      style={{
        zIndex: `${zIndex}`,
        position: "absolute",
        overflow: "hidden",
      }}
    >
      <Page pid={cid} zIndex={zIndex} position={position} />
    </div>
  );
};

export default StackItem;
