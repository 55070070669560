import React from "react";

const NavDot = (props) => {
  const handleClick = () => {
    props.isClicked(props.viewId);
  };

  return (
    <span
      className={`p-0 w-100 d-flex nav-dot align-items-center justify-content-center`}
      style={{
        width: "1.2rem",
        height: "1.2rem",
        margin: "1px 0",
      }}
      onClick={handleClick}
      title={props.text}
    >
      <span
        id={`${props.viewId}-dot`}
        className={`bi bi-circle-fill d-flex align-items-center justify-content-center p-0 m-0 ${
          props.active ? "text-secondary opacity-100" : "text-light opacity-75"
        }`}
        style={{
          fontSize: `${props.active ? "0.8rem" : "0.5rem"}`,
          transition: "0.2s ease-out",
          width: "1rem",
          height: "1rem",
        }}
      ></span>
    </span>
  );
};

export default NavDot;
