import React from "react";
import { Container } from "react-bootstrap";
import faceBg from "../assets/illustration/Brazuca - UI Design.png";

const FacePage = () => {
  return (
    <div
      id="cover"
      className="position-absolute d-flex flex-column h-100 w-100 p-0"
    >
      <Container id="cover-content" fluid={true} className="flex-fill">
        <img
          className="position-absolute ms-5 bottom-0 start-50"
          style={{
            height: "25vw",
            width: "calc(400/310*25vw)",
            transform: "translate(-15vw,-10vh)",
            opacity: "50%",
          }}
          src={faceBg}
          alt="bg-svg"
        ></img>
        <div
          className="container-lg position-relative h-100 d-flex flex-column justify-content-center "
          style={{ zIndex: 2 }}
        >
          <div className="container-md d-flex align-items-center  flex-column-reverse flex-md-row  px-md-5 px-sm-4">
            <div
              className="flex-fill w-100 ps-sm-5 ms-sm-5  text-start"
              style={{ textShadow: "3px 4px 10px rgba(0,0,0,0.62)" }}
            >
              <h5 className="cover-text">Hey, I'm</h5>
              <h1 id="cover-name" className="text-secondary">
                Shinjith P R,
              </h1>
              <h6 className="cover-text">an enginering student,</h6>
              <h6 className="cover-text">software developer in training,</h6>
              <h6 className="cover-text">... and just a wandering soul.</h6>
            </div>
            <div className="d-flex align-self-md-center align-self-end me-lg-5 align-items-start justify-content-end pe-sm-5 pe-lg-5">
              <div
                id="profile-img"
                className=" border border-3 border-primary shadow-lg me-3"
              ></div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FacePage;
