import React from "react";
import About from "./About";
import Contact from "./Contact";
import FacePage from "./FacePage";
import Footer2 from "./Footer2";
import Projects from "./Projects";

const Page = ({ pid, zIndex }) => {
  switch (pid) {
    case 0:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <FacePage />
        </div>
      );
    case 1:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <About />
        </div>
      );
    case 2:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <Projects />
        </div>
      );
    case 3:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <Contact />
        </div>
      );
    case 4:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <Footer2 />
        </div>
      );
    default:
      return (
        <div
          className="position-relative w-100 h-100 border border-dark border-3 p-0"
          style={{ zIndex: `${zIndex}` }}
        >
          <FacePage />
        </div>
      );
  }
};

export default Page;
