import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import resume from '../assets/resume.pdf'


const Header = ({ showIntro, color }) => {
  // const [isBeyondNav, setIsBeyondNav] = useState(false);
  const [navBgLight, setNavBgLight] = useState(true);
  const [navExpanded, setNavExpanded] = useState(false);
  // const [scrollTopVal, setScrollTopVal] = useState(0);
  // const [yOffsetVal, setYOffsetVal] = useState(0);
  // const [scrollVal, setScrollVal] = useState(0);
  // useEffect(() => {
  //   setNavBgLight(color === 1 ? false : true);
  // }, [color]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollTopVal(document.documentElement.scrollTop);
  //     setYOffsetVal(window.pageYOffset);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   setScrollVal(yOffsetVal || scrollTopVal);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [yOffsetVal, scrollTopVal]);

  // useEffect(() => {
  //   setIsBeyondNav(scrollVal > 300 ? true : false);
  //   setNavBgLight(isBeyondNav ? false : true);
  // }, [scrollVal]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const handleNavToggle = async () => {
    if (navExpanded) {
      setNavExpanded(!navExpanded);
      await sleep(350);
      // if (isBeyondNav) setNavBgLight(false);
      // else
      if (!navExpanded) setNavBgLight(false);
      else setNavBgLight(true);
    } else {
      // if (isBeyondNav) setNavBgLight(false);
      // else
      if (!navExpanded) setNavBgLight(false);
      else setNavBgLight(true);
      await sleep(50);
      setNavExpanded(!navExpanded);
    }
  };

  return (
    <Container
      as="header"
      fluid={true}
      className={`fixed-top px-0 ${navBgLight ? "" : "shadow-sm bg-dark"} ${
        navExpanded ? "border-bottom border-secondary border-5" : ""
      }`}
      style={{ zIndex: 50, width: "100vw" }}
    >
      <Navbar
        id="navbarid"
        bg={`${navBgLight ? "" : "dark"}`}
        variant={`${navBgLight ? "light" : "dark"}`}
        className={`${navBgLight ? "" : "navbar-dark text-light"} ${
          navExpanded ? "nav-shadow" : ""
        }`}
        expand="md"
        onToggle={handleNavToggle}
        collapseOnSelect={true}
        expanded={navExpanded}
        style={{ zIndex: 10 }}
      >
        <div className="container-lg">
          <Navbar.Brand
            href="#home"
            className={`ps-2 logo-text ${
              color ? "visually-hidden" : "text-secondary"
            }`}
            style={{ transition: "0.5s ease-out" }}
          >
            <h3>shinjith</h3>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbar-nav"
            label={`${navExpanded ? "close" : "open"} menu`}
          >
            {navExpanded ? (
              <span
                className="bi bi-x-lg"
                style={{ transition: "0.5s ease-out" }}
              ></span>
            ) : (
              <span
                className="bi bi-list"
                style={{ transition: "0.5s ease-out" }}
              ></span>
            )}
          </Navbar.Toggle>
          <Navbar.Collapse
            id="navbar-nav"
            className={`${color ? "visually-hidden" : ""}`}
          >
            <Nav className="ms-auto px-3 text-center">
              <Nav.Item>
                <Nav.Link href="#" onClick={showIntro}>
                  <i className="bi bi-play-circle me-2"></i>I'd Like To See
                  Intro
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href={resume}
                  rel="noreferrer"
                  download="shinjnith-resume.pdf"
                >
                  <i className="bi bi-file me-2"></i>Resume
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <span className="d-md-none d-block text-center">
              <hr />
              <div>
                <a
                  href="https://github.com/shinjith-dev/"
                  className="mx-2 text-secondary"
                  style={{ fontSize: "25px" }}
                >
                  <i className="bi fa-lg bi-github"></i>
                </a>
                <a
                  href="https://in.linkedin.com/in/shinjithkanhangad"
                  className="mx-2 text-secondary"
                  style={{ fontSize: "25px" }}
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </span>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </Container>
  );
};

export default Header;
