import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import projectsData from "../data/projects";

const Projects = () => {
  const [index, setIndex] = useState(0);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div
      id="projects"
      className="container-fluid p-3 position-relative h-100 d-flex align-items-center"
    >
      <div className="container-md">
        <h3 className="display-5 p-3 ps-3 ps-lg-5 ms-lg-3 ms-xl-5">Projects</h3>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          variant="dark"
          interval={5000}
        >
          {projects.length > 0 &&
            projects.map((project) => (
              <Carousel.Item className="pb-5 pt-2" key={project.id}>
                <Card
                  className="my-2 text-light shadow overflow-hidden mx-auto"
                  style={{ backgroundColor: "#303f69" }}
                >
                  <Row className="g-0">
                    <Col sm={6}>
                      <img
                        className="rounded-start card-img"
                        src={project?.cover}
                        alt={project?.id}
                      />
                    </Col>
                    <Col sm={6} className="proj-cards">
                      <Container
                        fluid={true}
                        className="bg-secondary mt-3"
                        style={{ height: "2px" }}
                      ></Container>
                      <Card.Body className="d-flex flex-column h-100">
                        <Card.Title>{project?.title}</Card.Title>
                        <Card.Subtitle className="text-muted">
                          {project?.technologies}
                        </Card.Subtitle>
                        <Card.Text>{project?.desc}</Card.Text>
                        <div
                          className="d-flex justify-content-evenly"
                          style={{ width: "100%" }}
                        >
                          <a
                            href={project?.liveLink}
                            className="btn btn-dark"
                            style={{ width: "40%" }}
                            title="Show Live Site"
                          >
                            Live
                          </a>
                          <a
                            href={project?.githubLink}
                            className="btn btn-outline-secondary"
                            style={{ width: "40%" }}
                            title="Source Code on Github"
                          >
                            Source
                          </a>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Carousel.Item>
            ))}

          {/* <Carousel.Item className="pb-5 pt-2">
            <Card
              className="my-2 text-light shadow overflow-hidden mx-auto"
              style={{ backgroundColor: "#303f69" }}
            >
              <Row className="g-0">
                <Col sm={6}>
                  <img
                    className="rounded-start card-img"
                    src={chatCover}
                    alt="chat-ui"
                  ></img>
                </Col>
                <Col sm={6} className="proj-cards">
                  <Container
                    fluid={true}
                    className="bg-secondary mt-3"
                    style={{ height: "2px" }}
                  ></Container>
                  <Card.Body className="d-flex flex-column h-100">
                    <Card.Title>Chat UI</Card.Title>
                    <Card.Subtitle className="text-muted">
                      HTML · CSS · JavaScript
                    </Card.Subtitle>
                    <Card.Text>
                      Basic classic web front-end for chat-system.
                    </Card.Text>
                    <div
                      className="d-flex justify-content-evenly"
                      style={{ width: "100%" }}
                    >
                      <a
                        href="https://shinjith-dev.github.io/ChatApp/"
                        className="btn btn-dark"
                        style={{ width: "40%" }}
                        title="Show Live Site"
                      >
                        Live
                      </a>
                      <a
                        href="https://github.com/shinjith-dev/ChatApp"
                        className="btn btn-outline-secondary"
                        style={{ width: "40%" }}
                        title="Source Code on Github"
                      >
                        Source
                      </a>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Carousel.Item>
          <Carousel.Item className="pb-5 pt-2">
            <Card
              className="my-2 text-light shadow overflow-hidden mx-auto"
              style={{ backgroundColor: "#303f69" }}
            >
              <Row className="g-0">
                <Col sm={6}>
                  <img
                    className="rounded-start card-img"
                    src={calcCover}
                    alt="js-calculator"
                  ></img>
                </Col>
                <Col sm={6} className="proj-cards">
                  <Container
                    fluid={true}
                    className="bg-secondary mt-3"
                    style={{ height: "2px" }}
                  ></Container>
                  <Card.Body className="d-flex flex-column h-100">
                    <Card.Title>JavaScript Calculator</Card.Title>
                    <Card.Subtitle className="text-muted">
                      HTML · CSS · JavaScript
                    </Card.Subtitle>
                    <Card.Text>
                      A simple responsive standard web calculator made using
                      pure JavaScript.
                    </Card.Text>
                    <div
                      className="d-flex justify-content-evenly"
                      style={{ width: "100%" }}
                    >
                      <a
                        href="https://shinjith-dev.github.io/JS-Calculator/"
                        className="btn btn-dark"
                        style={{ width: "40%" }}
                        title="Show Live Site"
                      >
                        Live
                      </a>
                      <a
                        href="https://github.com/shinjith-dev/JS-Calculator"
                        className="btn btn-outline-secondary"
                        style={{ width: "40%" }}
                        title="Source Code on Github"
                      >
                        Source
                      </a>{" "}
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Carousel.Item> */}
        </Carousel>
        <div className="p-2 d-flex justify-content-center">
          <Button variant="primary" className="mx-auto shadow">
            <a
              style={{ textDecoration: "none" }}
              className="text-light"
              href="https://github.com/shinjith-dev?tab=repositories"
            >
              More Projects
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Projects;
