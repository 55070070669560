import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const SESSION_KEY = "shinjith-dev-session-key";
sessionStorage.clear(SESSION_KEY);
const stackOrder = [0, 1, 2, 3, 4];
sessionStorage.setItem(SESSION_KEY, JSON.stringify(stackOrder));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
