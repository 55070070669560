import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import StackItem from "./StackItem";

const SESSION_KEY = "shinjith-dev-session-key";
const stacker = [0, 1, 2, 3, 4];

const PageStack = ({ chosenPage }) => {
  const [animated, setAnimated] = useState(false);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    const storedStack = JSON.parse(sessionStorage.getItem(SESSION_KEY));
    if (storedStack) {
      storedStack.map((item, index) => {
        stacker[index] = item;
        return item;
      });
    }
    async function bringFront() {
      await sleep(800);
      let array = stacker.slice();
      array.splice(stacker.indexOf(chosenPage), 1);
      array.unshift(chosenPage);
      array.map((item, index) => {
        stacker[index] = item;
        return item;
      });
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(stacker));
      setAnimated(true);
    }
    if (!animated) bringFront();
  }, []);

  return (
    <Container
      fluid={true}
      id="page-stack"
      className="d-flex align-items-center top-0 position-absolute p-0"
      style={{ height: "100vh", width: "100vw", zIndex: 0, overflow: "hidden" }}
    >
      <div className={`container-fluid w-100 h-100 stack-cont p-0 zoom-effect`}>
        {stacker.map((item, index) => {
          return (
            <StackItem
              key={index}
              cid={item}
              position={index}
              zIndex={index}
              chosenPage={chosenPage}
              animated={animated}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default PageStack;
