import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import NavDot from "./NavDot";

const Navigator = ({ dotPosition, linkClicked }) => {
  const [activeDot, setActiveDot] = useState([1, 0, 0, 0, 0]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dotPosition(activeDot.indexOf(1));
  }, [activeDot]);

  useEffect(() => {
    isActive(dotNames[linkClicked.indexOf(1)]);
  }, [linkClicked]);

  const dotNames = ["cover", "about", "projects", "contact", "footer"];

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const findIcon = () => {
    let index = activeDot.indexOf(1);
    switch (index) {
      case 0:
        return "bi-house-door-fill";
      case 1:
        return "bi-info-circle-fill";
      case 2:
        return "bi-brush-fill";
      case 3:
        return "bi-telephone-fill";
      case 4:
        return "bi-balloon-heart-fill";
      default:
        return "bi-house-door-fill";
    }
  };

  const handleUpClick = () => {
    if (activeDot.indexOf(1) !== 0) {
      let to = Math.max(activeDot.indexOf(1) - 1, 0);
      isActive(dotNames[to]);
    }
  };

  const handleDownClick = () => {
    if (activeDot.indexOf(1) !== 4) {
      let active = Math.min(activeDot.indexOf(1) + 1, 4);
      isActive(dotNames[active]);
    }
  };

  const isActive = async (item) => {
    if (dotNames.indexOf(item) !== activeDot.indexOf(1)) {
      let array = dotNames.map((value) => {
        return value === item ? 1 : 0;
      });
      if (!array.includes(1)) array[0] = 1;
      setActiveDot(array);
      dotPosition(activeDot.indexOf(1));
      setDisable(true);
      setLoading(true);
      await sleep(800);
      setLoading(false);
      await sleep(200);
      setDisable(false);
    }
  };

  return (
    <div
      id="navigator"
      className="text-white position-fixed p-0"
      style={{
        width: "2.2rem",
        height: "14.6rem",

        right: "1.5vw",
        zIndex: 3,
      }}
    >
      <Container
        className="px-0 rounded rounded-5 border border-primary my-2"
        style={{
          padding: "5px 0",
          backgroundColor: "var(--primary-10)",
        }}
      >
        <div
          id="scroll-btns"
          className="w-100 px-1 d-flex flex-column align-items-center justify-content-between h-100"
        >
          <div
            className="border rounded-circle border-primary p-1 d-flex align-items-center justify-content-center opacity-75"
            style={{ height: "1.7rem", width: "1.7rem" }}
            onClick={!disable ? handleUpClick : undefined}
          >
            <span
              className="bi bi-caret-up-fill position-relative "
              style={{
                transform: "translateY(-10%)",
              }}
            ></span>
          </div>

          <div
            className="p-1 p-1 d-flex align-items-center justify-content-center"
            style={{
              height: "1.8rem",
              width: "1.8rem",
              transition: "0.5s ease-out",
            }}
          >
            {loading ? (
              <Spinner
                className="w-100 h-100"
                animation="border"
                variant="secondary"
              />
            ) : (
              <span
                className={`bi ${findIcon()} position-relative text-secondary`}
                style={{ fontSize: "1.2rem" }}
              ></span>
            )}
          </div>

          <div
            className="border rounded-circle border-primary p-1 d-flex align-items-center justify-content-center"
            style={{ height: "1.7rem", width: "1.7rem" }}
            onClick={!disable ? handleDownClick : undefined}
          >
            <span
              className="bi bi-caret-down-fill position-relative opacity-75"
              style={{ transform: "translateY(10%)" }}
            ></span>
          </div>
        </div>
      </Container>
      <Container
        className="py-2 d-flex align-items-center justify-content-center px-0 text-white rounded rounded-5 border border-primary my-1"
        style={{
          padding: "5px 0",

          backgroundColor: "var(--primary-10)",
        }}
      >
        <div className="w-100 p-0 d-flex flex-column align-items-center">
          <NavDot
            viewId="cover"
            text="Home"
            active={activeDot[0] ? true : false}
            isClicked={(value) => !disable && isActive(value)}
          />
          <NavDot
            text="About"
            viewId="about"
            active={activeDot[1] ? true : false}
            isClicked={(value) => !disable && isActive(value)}
          />
          <NavDot
            text="Projects"
            viewId="projects"
            active={activeDot[2] ? true : false}
            isClicked={(value) => !disable && isActive(value)}
          />
          <NavDot
            text="Contact"
            viewId="contact"
            active={activeDot[3] ? true : false}
            isClicked={(value) => !disable && isActive(value)}
          />
          <NavDot
            text="Footer"
            viewId="footer"
            active={activeDot[4] ? true : false}
            isClicked={(value) => !disable && isActive(value)}
          />
        </div>
      </Container>
    </div>
  );
};

export default Navigator;
