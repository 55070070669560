import "./App.css";
import "./scss/App.scss";
import Intro from "./components/Intro";
import { useEffect, useState } from "react";
import MainPage from "./components/MainPage";

const LOCAL_STORAGE_KEY = "shinjith-dev-intro-status";

function App() {
  const [introShown, setIntroShown] = useState(false);
  const [introOnce, setIntroOnce] = useState(false);
  const [forceShowIntro, setForceShowIntro] = useState(false);
  const [seconds, setSeconds] = useState(12);

  useEffect(() => {
    const introData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const now = new Date();
    if (introData && now.getTime() < introData.expiry)
      setIntroOnce(introData.value);
  }, []);

  useEffect(() => {
    let introData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const now = new Date();
    const oneDay = 86400000;
    introData = {
      value: introData && now.getTime() > introData.expiry ? false : true,
      expiry: now.getTime() + oneDay,
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(introData));
  }, [introOnce]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const toggleMain = async () => {
    await sleep(1000);
    document.getElementById("terminal-window").style.overflow = "visible";
    await sleep(4000);
    document.getElementById("window-cont").style.maxWidth = "1500px";
    setIntroShown(!introShown);
    setIntroOnce(true);
    setForceShowIntro(false);
  };

  const showIntro = () => {
    setForceShowIntro(true);
    setSeconds(12);
    setIntroShown(false);
  };

  return (
    <div id="mainViewId" className="App">
      {introOnce && !forceShowIntro ? (
        <MainPage showIntro={showIntro} />
      ) : (
        <Intro toggleMain={toggleMain} counterVal={seconds} />
      )}
    </div>
  );
}

export default App;
