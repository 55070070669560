import React from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import aboutBg from "../assets/BGs/bgAbout.png";

const About = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Open In Instagram
    </Tooltip>
  );

  return (
    <div id="about" className="container-fluid position-relative h-100 ">
      <div className="container-md d-grid align-items-center h-100">
        <img
          className="position-absolute ms-5 top-0 end-0"
          style={{
            height: "25vw",
            width: "calc(400/310*25vw)",
            transform: "translate(-8vw,10vh)",
            opacity: "50%",
          }}
          src={aboutBg}
          alt="bg"
        ></img>
        <Row className="px-lg-5">
          <Col className="p-3 p-sm-4 p-md-5 ms-lg-4">
            <h3 className="dislay-5 px-3 mb-4">About</h3>
            <p className="text-about">
              {"Passionate. Creative. Explorer. Aspiring software developer."}
            </p>
            <p className="text-about">
              {
                " BTech student at LBS College of Engineering. JNV Kasaragod alumna."
              }
            </p>
            <p className="text-about">
              {
                "Intrigued by design, travel, music, strategy, art, philosophy, delicious food, and even conversions pique my interest."
              }
            </p>
            <p className="text-about">
              {
                "seeking to be inspired, to put effort on things that make a difference, to envision the improbable, and to be surrounded by those who makes me feel myself."
              }
            </p>
            <p className="text-about">
              Say hi on Instagram{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <a
                  href="https://www.instagram.com/shinjith_/"
                  style={{ textDecoration: "none" }}
                >
                  @shinjith_
                </a>
              </OverlayTrigger>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
