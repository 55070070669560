import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import resume from "../assets/resume.pdf";

const Footer2 = ({ clickLink }) => {
  return (
    <footer id="footer" className="container-fluid h-100 w-100 ">
      <div className="container-md text-light h-100">
        <Row className=" align-items-center align-content-end align-content-md-center h-100">
          <Col md={4} className="mb-5">
            <div
              className="d-flex justify-content-center text-secondary mx-auto flex-column"
              style={{ width: "12rem" }}
            >
              <h1
                className="logo-text text-center"
                style={{ fontSize: "3rem" }}
              >
                shinjith
              </h1>

              <a
                className="btn btn-outline-secondary mt-2"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdQnc9O295SIgTMdDJpO541m3DKphKDJXD0k4BxnHBb3HpkUA/viewform?usp=sf_link"
                style={{ textDecoration: "none" }}
              >
                Build Me A Website!
              </a>
            </div>
          </Col>
          <Col md={4} className="mb-5">
            <Nav
              className="text-light opacity-75 d-flex flex-column justify-content-center text-secondary mx-auto ps-3 p-2 border-start border-light"
              style={{ fontSize: "1.2rem", width: "9rem" }}
            >
              <Nav.Item
                onClick={() => {
                  clickLink(0);
                }}
              >
                <a className="nav-link p-1 text-light my-1" href="#cover">
                  <i className="bi bi-house-door me-2"></i>Home
                </a>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  clickLink(1);
                }}
              >
                <a className="nav-link p-1 text-light my-1" href="#about">
                  <i className="bi bi-text-paragraph me-2"></i>About
                </a>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  clickLink(2);
                }}
              >
                <a className="nav-link p-1 text-light my-1" href="#projects">
                  <i className="bi bi-brush me-2"></i>Projects
                </a>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  clickLink(3);
                }}
              >
                <a className="nav-link p-1 text-light my-1" href="#contact">
                  <i className="bi bi-telephone me-2"></i>Contact
                </a>
              </Nav.Item>
              <Nav.Item>
                <a
                  className="nav-link p-1 text-light my-1"
                  target="_blank"
                  href={resume}
                  rel="noreferrer"
                  download="shinjnith-resume.pdf"
                >
                  <i className="bi bi-file me-2"></i>Resume
                </a>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={4} className="mb-5">
            <div
              className="d-flex justify-content-center  mx-auto p-2 border-start border-secondary"
              style={{ fontSize: "1.3rem", width: "12rem" }}
            >
              <Nav.Link href="https://in.linkedin.com/in/shinjithkanhangad">
                <i className="text-secondary bi bi-linkedin px-1"></i>
              </Nav.Link>
              <Nav.Link href="https://github.com/shinjith-dev/">
                <i className="text-secondary bi bi-github px-1 mx-1"></i>
              </Nav.Link>
              <Nav.Link href="https://www.instagram.com/shinjith_/">
                <i className="bi text-secondary bi-instagram px-1"></i>
              </Nav.Link>
              <Nav.Link href="https://www.facebook.com/shinjith.kanhangad">
                <i className="bi text-secondary bi-facebook px-1 mx-1"></i>
              </Nav.Link>
              <Nav.Link href="https://twitter.com/RShinjith">
                <i className="bi text-secondary bi-twitter px-1"></i>
              </Nav.Link>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer2;
