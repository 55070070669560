import reacttodo from "../assets/images/todo-cover.png";
import jscalc from "../assets/images/calc-cover.png";
import movieloft from "../assets/images/movieloft-cover.jpg";

const projects = [
  {
    id: "movieloft",
    title: "Movie-Loft",
    cover: movieloft,
    technologies: "React · MaterialUI · API",
    desc: "Movie Loft is a web-based movie database client with a minimal user interface.",
    githubLink: "https://github.com/shinjith-dev/movie-loft",
    liveLink:"https://movie-loft.netlify.app/"
  },
  {
    id: "reacttodo",
    title: "React Todo",
    cover: reacttodo,
    technologies: "HTML · CSS · React",
    desc: "Todo app made using React framework with awesome responsive UI.",
    githubLink: "https://github.com/shinjith-dev/React-Todo",
    liveLink: "https://shinjith-dev.github.io/React-Todo/",
  },
  {
    id: "jscalc",
    title: "Javascript Calculator",
    cover: jscalc,
    technologies: "HTML · CSS · JavaScript",
    desc: "A simple responsive standard web calculator made using pure JavaScript.",
    githubLink: "https://github.com/shinjith-dev/JS-Calculator",
    liveLink: "https://shinjith-dev.github.io/JS-Calculator/",
  },
];

export default projects;
